.manage-doctor-container {
    padding: 5px;

    .manage-doctor-title {
        text-align    : center;
        margin        : 15px 0;
        font-size     : 20px;
        text-transform: uppercase;
        font-weight   : 600;
    }

    .save-content-doctor {
        margin-top   : 20px;
        margin-bottom: 30px;
        border-radius: 3px;
        outline      : none;
        border       : none;
        padding      : 5px;
        background   : orange;
        cursor       : pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    .create-content-doctor {
        margin-top   : 20px;
        margin-bottom: 30px;
        border-radius: 3px;
        outline      : none;
        border       : none;
        padding      : 5px;
        background   : rgb(11, 93, 216);
        cursor       : pointer;
        color        : white;

        &:hover {
            opacity: 0.9;
        }
    }

    .more-infor {
        display       : flex;
        gap           : 15px;
        padding-bottom: 15px;

        .content-left {
            width: 35%;
        }

        .content-right {
            width: 65%;
        }
    }
}