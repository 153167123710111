/*
 * General styles
 */
body,
html {
  background-image : "#fff";
  background-repeat: no-repeat;
  background-size  : cover;
  font-family      : $fontmain;
  font-size        : $base-size - 2px;
  height           : 100vh;
  overflow         : hidden;
}

.content-container {
  .title {
    text-align    : center;
    text-transform: uppercase;
    font-weight   : bold;
    font-size     : $base-size + 4px;
    margin-top    : 15px;
    color         : $colormain;
  }

  .content {
    margin            : 15px 30px;
    box-shadow        : 2px 2px 4px 0 $box-shadow-color;
    -webkit-box-shadow: 2px 2px 4px 0 $box-shadow-color;
  }
}