.login-background {
    // background: rgb(34,193,195);
    height    : 100vh;
    background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);

    .login-container {
        width           : 400px;
        border-radius   : 10px;
        background-color: white;
        position        : absolute;
        margin          : auto;
        top             : 0;
        bottom          : 0;
        right           : 0;
        left            : 0;
        height          : 450px;

        .login-content {
            padding: 10px;

            .text-login {
                text-align : center;
                font-weight: 600;
                font-size  : 24px;
                padding-top: 10px;
            }

            .login-input {
                margin: 7px 0;

                .custom-input-password {
                    position: relative;

                    i {
                        position: absolute;
                        right   : 5px;
                        top     : 10px;
                        cursor  : pointer;
                    }
                }
            }

            .btn-login {
                width        : 100%;
                margin       : 10px 0;
                height       : 30px;
                border-radius: 15px;
                outline      : none;
                border       : none;
                background   : linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
                color        : white;
            }

            .forgot-password {
                font-size: 12px;
            }

            .social-login {
                display        : flex;
                text-align     : center;
                justify-content: center;
                margin-top     : 10px;

                .google {
                    font-size      : 30px;
                    background     : #c32424;
                    border-radius  : 50%;
                    height         : 50px;
                    width          : 50px;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    color          : white;
                    margin-right   : 10px;
                }

                .facebook {
                    font-size      : 30px;
                    background     : #307aaf;
                    border-radius  : 50%;
                    height         : 50px;
                    width          : 50px;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    color          : white;
                }
            }
        }
    }
}