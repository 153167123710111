.manage-schedule-container {
    .m-s-title {
        text-transform: uppercase;
        margin-top    : 15px;
        margin-bottom : 15px;
        font-weight   : 600;
        font-size     : 17px;
        text-align    : center;
    }

    .pick-hour-container {
        padding-top   : 20px;
        padding-bottom: 20px;
        display       : flex;
        gap           : 20px;
        flex-wrap     : wrap;

        button {
            padding: 5px 15px;
            border : 1px solid grey;
        }
    }

    .btn-schedule {
        &.active {
            background-color: orange;
        }
    }

    .btn-save-schedule {
        margin-top: 20px;
    }
}